import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import SEO from '../components/seo'
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-custom'
import Footer from '../containers/layout/footer/footer-custom'
import PageHeader from '../components/pageheader'
import ContactFormArea from '../containers/contact-form-area'

const ContactUsPage = ({ pageContext, location }) => {
    const intl = useIntl()

	return (
		<Layout location={location}>
			<SEO
				title={intl.formatMessage({
					id: 'page.contact.title',
				})}
			/>
			<Header />
			<PageHeader
				pageContext={pageContext}
				location={location}
                title={intl.formatMessage({
                    id: 'page.contact.title',
                })}
			/>
			<main className="site-wrapper-reveal">
                <ContactFormArea
                    titleID="page.contact.contact.title"
                    descID="page.contact.contact.desc"
                />
			</main>
			<Footer />
		</Layout>
	)
}

export default ContactUsPage
